import * as React from 'react';
// @ts-ignore
import arrowRight from '../../images/arrow-right.svg';
const WorkingHereSection: React.FC = () => {
	return (
		<section className={'px-3 py-14'}>
			<div className={'container mx-auto flex flex-col py-14'}>
				<h2
					className={
						'mx-auto max-w-5xl text-center font-rubik text-[52px] font-bold text-bt-gray max-md:text-4xl'
					}
				>
					We think you will enjoy working here as much as our team
					does!
				</h2>
				<a
					className={
						'mx-auto mt-12 flex flex-row text-center font-rubik text-4xl text-bt-blue hover:text-bt-red max-md:text-lg'
					}
					href={'/team/'}
					target={'_blank'}
				>
					Click here to see a typical day at Belle Tire{' '}
					<img
						className={'ml-3 w-7 max-md:w-5'}
						src={arrowRight}
						alt={'Right arrow'}
					/>{' '}
				</a>
			</div>
		</section>
	);
};

export default WorkingHereSection;
