import * as React from 'react';
import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../layouts/Layout';

// @ts-ignore
import heroImage from '../images/hero-background.png';
// @ts-ignore
import heroImage1 from '../images/hero-background2.jpeg';
// @ts-ignore
import heroImage2 from '../images/hero-background3.jpeg';

import JobSearchForm from '../components/JobSearchForm/JobSearchForm';
import ApplyNow from '../components/ApplyNow/ApplyNow';
import WorkingHereSection from '../components/WorkingHereSection/WorkingHereSection';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import OurJobSection from '../components/OurJobSection/OurJobSection';
import RoadAheadVideo from '../components/RoadAheadVideo/RoadAheadVideo';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'swiper';

const IndexPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	SwiperCore.use([Autoplay]);

	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<section>
				<Swiper
					spaceBetween={0}
					loop={true}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
				>
					<SwiperSlide>
						<section
							style={{ backgroundImage: `url('${heroImage}')` }}
							className={
								'flex h-[600px] w-full flex-col justify-end bg-cover bg-top px-3 max-sm:h-[400px]'
							}
						></section>
					</SwiperSlide>
					<SwiperSlide>
						<section
							style={{ backgroundImage: `url('${heroImage1}')` }}
							className={
								'flex h-[600px] w-full flex-col justify-end bg-cover bg-top px-3 max-sm:h-[400px]'
							}
						></section>
					</SwiperSlide>
					<SwiperSlide>
						<section
							style={{ backgroundImage: `url('${heroImage2}')` }}
							className={
								'flex h-[600px] w-full flex-col justify-end bg-cover bg-top px-3 max-sm:h-[400px]'
							}
						></section>
					</SwiperSlide>
				</Swiper>
				<div
					className={
						'container relative z-10 mx-auto mt-[-70px] flex max-sm:mt-[-90px] max-sm:mb-7'
					}
				>
					<h1
						className={
							'flex bg-white p-5 text-4xl font-bold text-black max-sm:text-2xl'
						}
					>
						We are looking for the best people.
					</h1>
				</div>
			</section>
			<JobSearchForm data={data} />
			<ApplyNow />
			<RoadAheadVideo />
			<WorkingHereSection />
			<JobAlerts />
			<OurJobSection />
		</Layout>
	);
};

export default IndexPage;
