import * as React from 'react';

const RoadAheadVideo: React.FC = () => {
	return (
		<section className={'bg-bt-light-gray px-3 py-20'}>
			<div
				className={
					'container mx-auto flex flex-col items-center justify-center text-center'
				}
			>
				<h2 className={'max-w-6xl font-rubik text-4xl text-bt-gray'}>
					We look proudly at the road that brought us here and boldly
					at the road ahead!
				</h2>
				<iframe
					className={
						'mx-auto mt-16 aspect-video w-full max-w-5xl rounded-3xl'
					}
					src="https://www.youtube.com/embed/J5tgn4DU95I"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				/>
			</div>
		</section>
	);
};

export default RoadAheadVideo;
