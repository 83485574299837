import * as React from 'react';
// @ts-ignore
import applyNowBgImage from '../../images/apply-now-image.png';
// @ts-ignore
import arrowRight from '../../images/arrow-right.svg';
const ApplyNow: React.FC = () => {
	interface CardType {
		text: string;
		url: string;
	}
	const retailCards: Array<CardType> = [
		{
			text: 'Tire Technician',
			url: '/careers/tire-technician-5000874408006'
		},
		{
			text: 'Auto Technician',
			url: '/careers/automotive-technician-must-have-prior-experience-5000878150106'
		},
		{
			text: 'Manager',
			url: '/careers/manager-in-training-5000876998206'
		},
		{
			text: 'Assistant Manager',
			url: '/careers/assistant-manager-sales-associate-5000894271106'
		},
		{
			text: 'Auto Glass Installer / Technician',
			url: '/search/?term=Auto%20Glass'
		}
	];
	const dcCards: Array<CardType> = [
		{
			text: 'Warehouse Associate',
			url: '/search/?term=Warehouse'
		},
		{
			text: 'Delivery Driver',
			url: '/search/?term=delivery+driver'
		},
		{
			text: 'Open Support Center Positions',
			url: '/search/?term=corporate'
		}
	];

	const generateCards = (cards: Array<CardType>) => {
		return cards.map((card) => {
			return (
				<div className={'flex flex-col pt-2'}>
					<a
						className={
							'my-2 flex flex cursor-pointer flex-col border-r-[24px] border-bt-blue bg-white p-6'
						}
						href={card.url}
						target={'_blank'}
					>
						<span
							className={
								'font-rubik text-2xl font-bold text-black'
							}
						>
							{card.text}
						</span>
						<span
							className={
								'mt-4 flex flex-row font-rubik font-bold text-bt-blue'
							}
						>
							Apply Now{' '}
							<img
								src={arrowRight}
								className={'ml-2'}
								alt={'Apply now right arrow'}
							/>
						</span>
					</a>
				</div>
			);
		});
	};
	return (
		<section
			style={{ backgroundImage: `url('${applyNowBgImage}')` }}
			className={'bg-cover bg-top px-3'}
		>
			<div className={'container mx-auto py-7 pb-14 text-center'}>
				<h2 className={'py-5 text-4xl text-white'}>Apply Now!</h2>
				<div className={'flex flex-row max-lg:flex-col'}>
					<div
						className={
							'flex w-1/2 flex-col pr-6 text-left max-lg:mt-14 max-lg:w-full max-lg:pr-0'
						}
					>
						<h3
							className={
								'mb-1 font-rubik text-4xl font-normal text-white'
							}
						>
							Retail
						</h3>
						<div className={'w-full border-b'} />
						{generateCards(retailCards)}
					</div>
					<div
						className={
							'flex w-1/2 flex-col pl-6 text-left max-lg:mt-14 max-lg:w-full max-lg:pl-0'
						}
					>
						<h3
							className={
								'mb-1 font-rubik text-4xl font-normal text-white'
							}
						>
							Distribution/Support Center
						</h3>
						<div className={'w-full border-b'} />
						{generateCards(dcCards)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ApplyNow;
