import * as React from 'react';
// @ts-ignore
import ourJobBgImage from '../../images/our-job-background.png';
const OurJobSection: React.FC = () => {
	return (
		<section
			style={{ backgroundImage: `url('${ourJobBgImage}')` }}
			className={'bg-cover bg-top px-3 py-14'}
		>
			<div
				className={
					'container mx-auto flex flex-col py-14 text-center text-white'
				}
			>
				<h2
					className={
						'font-rubik text-[52px] font-medium max-md:text-4xl'
					}
				>
					Our Job
				</h2>
				<p
					className={
						'mx-auto max-w-4xl pb-14 font-rubik text-[26px] max-md:text-xl'
					}
				>
					Our job is to give our neighbors peace of mind and trust
					that we will get them back on the road fast and affordably.
				</p>
				<h2
					className={
						'font-rubik text-[52px] font-medium max-md:text-4xl'
					}
				>
					Our Goal
				</h2>
				<p
					className={
						'mx-auto max-w-4xl pb-14 font-rubik text-[26px] max-md:text-xl'
					}
				>
					Our goal is to become America's #1 trusted partner for
					complete tire service, vehicle maintenance and repair.
				</p>
			</div>
		</section>
	);
};

export default OurJobSection;
