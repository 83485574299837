import * as React from 'react';
// @ts-ignore
import { HeroSearch } from 'career-page-components';
import './JobSearchForm.css';

interface JobSearchFormProps {
	data: any;
}
const JobSearchForm: React.FC<JobSearchFormProps> = (
	props: JobSearchFormProps
) => {
	return (
		<section
			className={
				'section-job-search-form hero-form bg-bt-red py-4 px-3 font-rubik max-md:pb-6'
			}
		>
			<div
				className={
					'container mx-auto py-5 text-[32px] text-white max-md:text-center'
				}
			>
				Search Openings
			</div>
			<HeroSearch
				data={props.data}
				options={{
					submitButtonText: 'Search Roles'
				}}
				apiKey={'AIzaSyCPLqR_SVgctRijc8zUg_bCvsT3eh9ngoQ'}
			/>
		</section>
	);
};

export default JobSearchForm;
